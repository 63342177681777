
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';

export default defineComponent({
  name: 'Props',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();

    const tableOpts = {
      search: {
        fetch: true,
        criteria: {
          cols: []
        },
        remote: {
          action: https?.facade.pageProps
        }
      },
      cols: [
        {
          field: 'id',
          label: '属性ID',
          width: 100
        },
        {
          field: 'pk',
          label: '属性名',
          width: 200
        },
        {
          field: 'pv',
          label: '属性值'
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr,
          width: 120
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'pk',
              label: '属性名',
              required: true
            },
            {
              field: 'pv',
              label: '属性值',
              required: true
            },
            {
              field: 'remark',
              label: '备注'
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableStr,
              required: true
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.facade.createProps },
        update: { action: https?.facade.updateProps },
        delete: { action: https?.facade.deleteProps }
      }
    };
    return { tableOpts };
  }
});
